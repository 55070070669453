/**
 * Retrieve the the value for an config parameter
 * First it checks, if the window objects (set in the index.html (public/runtimeConfig.js)) 
 * contains the key-value pair. Via the runtimeConfig.js parameters can be configured after the 
 * frontend is built.
 * If the runtimeConfig.js doesn't contain a key, the process.evn if retruned
 * @param {string} name name of the config parameter
 * @returns value of the config param
 */ 
export default function runtimeConfig(name) {
  return window.config && window.config[name]
    ? window.config[name]
    : process.env[name];
}
