<template>
  <base-dialog title="Procedure">
    <section>
      <ol>
        <li>
          <strong>Running project, no more budget</strong>:<br />
          A new project will be added to Freshbooks only (not in Dropbox!)
          starting with the original project number followed by a sequence
          number.
          <br />
          E.g. "1375 Gazprom Badra", no more budget, new "1376-2 Gazprom
          Badra".<br />
          Once we have a PO number, we will add that to the FreshBooks project
          name. E.g. "1376-2 Gazprom Badra PO892399".<br />
        </li>
        <li>
          <strong>Future project, awaiting PO</strong>:<br />
          We will create a new project in Dropbox and in FreshBooks, and start
          booking hours under that already.
        </li>
        <li>
          <strong>No future project expected (and no PO)</strong>:<br />
          <ul>
            <li>
              If client has maintenance: file under client-specific maintenance
              project, e.g. "9000 TEPNL"
            </li>
            <li>
              If client has no maintenance,
              <em
                >ask yourself whether you should be doing this work at all!</em
              >
              If yes, file under "1345 Viewport Internal Development" with a
              clear indication of which client it is for, e.g. "[CEUNL] Sorted
              drawings" and flag either Rob or Carla.
            </li>
          </ul>
        </li>
      </ol>
    </section>
  </base-dialog>
</template>

