<template>
    <header>
        <slot></slot>
    </header>
</template>

<style scoped>
header {
    background-image: linear-gradient(-44deg,#e55801,#f38800);
    height: 64px;
    width: 100%; 
}
</style>