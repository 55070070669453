<template>
    <Button v-if="!link" :label="label" :type="type" :class="componentStyles" :icon="icon" :loading="isLoading" >
    </Button>
    <router-link v-else :to="to" :class="componentStyles">
      <i v-if="iconLink" :class="iconLink" class="p-mr-1"> </i>
      {{ label }}
    </router-link>
</template>

<script>

import Button from "primevue/button";

export default {
  components: {
    Button,
  },
  props: {
    link: {
      type: Boolean,
      required: false,
      default: false,
    },
    to: {
      type: String,
      required: false,
      default: "/",
    },
    mode: {
      type: String,
      required: false,
      default: "flat",
    },
    type: {
      type: String,
      required: false,
      default: "button",
    },
    small: {
      type: Boolean,
      required: false,
      default: false,
    },
    inline: {
      type: Boolean,
      required: false,
      default: false,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    label: {
      type: String,
      required: true,
      default: "Button Caption",
    },
    iconLink: {
      type: String,
      required: false,
      default: null,
    },
    icon: {
      type: String,
      required: false,
      default: null,
    },
    elementClass: {
      type: String,
      required: false,
      default: ""}
  },
  computed: {
    componentStyles() {
      let compStyle = this.mode;

      if (this.small) {
        compStyle = compStyle + " small";
      }

      if (this.inline) {
        compStyle = compStyle + " inline";
      }

      if(this.elementClass ) {
        compStyle+= " " + this.elementClass;
      }

      return compStyle;
    },
  },
};
</script>

<style scoped>
/* box-shadow: 0 0 0 0.2rem #;
  border-color: #2196F3; */

button,
a {
  padding: 0.75rem 1.5rem;
  font-family: inherit;
  background-image: linear-gradient(-44deg, #002842, #2196f3);
  border: 0px;
  color: white;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid #5c5c5c;
  font-size: inherit;
}

a {
  text-decoration: none;
}

.inline {
  padding: 0.25rem 0.75rem;
}

a:hover,
a:active,
button:hover,
button:active {
  background-image: linear-gradient(-44deg, #002842c2, #2196f3c2) !important; 
}

.small {
  padding: 0.25rem 0.75rem;
}

.flat {
  background-color: transparent !important;
  border: none !important;
}

.outline {
  background-image: none !important;
  background: transparent !important;
  border-color: #002842 !important;
  color: #002842 !important;
}

.flat:hover,
.flat:active {
  background-image: linear-gradient(-44deg, #002842c2, #2196f3c2) !important;
}

button:disabled,
button[disabled],
button:disabled.flat:hover,
button[disabled].flat:active {
  background-image: linear-gradient(-44deg, #00213669, #1d84d859) !important;
}

.outline:hover,
.outline:active,
.outline.selected {
  background-image: linear-gradient(-44deg, #002842, #2196f3) !important;
  color: white !important;
  border: 0px !important;
}

</style>
