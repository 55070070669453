<template>
  <the-header>
    <the-navigator></the-navigator>
  </the-header>
  <main>
    <Toast />
    <router-view></router-view>
  </main>
</template>

<script>
import TheHeader from "./components/UI/TheHeader.vue";
import TheNavigator from "./components/UI/TheNavigator.vue";
import Toast from "primevue/toast";
import { usePrimeVue } from "primevue/config";
import { useAuthStore } from './store/authStore';

export default {
  name: "App",
  components: {
    TheHeader,
    TheNavigator,
    Toast,
  },
  setup() {
    const primevue = usePrimeVue();
    // setting the first day of week to monday
    primevue.config.locale.firstDayOfWeek = 1;

    const authStore = useAuthStore();
    // Sync the vuex store with information of the JWT payload cookied
    authStore.syncStoreWithCookie();
  },
};
</script>

<style>
* {
  box-sizing: border-box;
}

html {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

body {
  margin: 0;
}

.card-layout-table {
  margin: 2rem auto;
  max-width: 98%;
  padding: 1rem;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
}

.small-card-layout {
  width: 75rem;
}

.document-number {
  background-color: #ffffe7;
  font-family: consolas;
  text-align: right;
  width: 25em;
}

input[type="text"],
input[type="number"],
input[type="password"],
textarea {
  /* color: #495057; */
  background: #ffffff;
  /* padding: 0.5rem 0.5rem; */
  border: 1px solid #ced4da;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    box-shadow 0.2s;
  appearance: none;
  border-radius: 3px;
}

select {
  background: #ffffff;
  /* padding: 0.5rem 0.5rem; */
  border: 1px solid #ced4da;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    box-shadow 0.2s;
  border-radius: 3px;
}

input[type="text"]:enabled:hover,
input[type="number"]:enabled:hover,
input[type="password"]:enabled:hover,
select:enabled:hover,
textarea:enabled:hover {
  border-color: #002842;
}

input[type="text"]:enabled:focus,
input[type="number"]:enabled:focus,
input[type="password"]:enabled:focus,
select:enabled:focus,
textarea:enabled:focus {
  outline: 0 none;
  outline-offset: 0;  
  box-shadow: 0 0 0 0.2rem #a6d5fa;
  border-color: #2196F3;
}

input:read-only,
textarea:read-only {
  background-color: #cccccc;
}

/*To overwrite the padding settings  */
.p-p-1 .p-dropdown-label.p-inputtext {
  padding: 0 !important;
}

a {
  cursor: pointer;
}

.custombar .p-scrollpanel-wrapper {
    border-right: 9px solid #f4f4f4;
}

.custombar .p-scrollpanel-bar {
    background-color: #1976d2 !important;
    opacity: 1;
    transition: background-color .3s;
}

.custombar .p-scrollpanel-bar:hover {
    background-color: #135ba1;
}

.scrollbox-inside-table .p-scrollpanel-content {
  width: 29rem;
  max-width: 29rem;;
}

span pre{
  white-space: pre-wrap !important;
}
</style>
