<template>
<div class="p-grid p-mt-5" style="width:98%">
  <div class="card p-col-8 p-ml-5">
    <slot></slot>
  </div>
  <div class="card p-col p-ml-5">
    <slot name="card2"></slot>
  </div>
</div>
</template>

<style scoped>
.card {
  padding: 1rem;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
}
</style>