import { createRouter, createWebHistory } from "vue-router";
import TheHome from "./pages/home/TheHome.vue";
import { useAuthStore } from './store/authStore';

// Lazy loading the componentes - only if route is clicked
const TheProjects = () =>
  import(
    /* webpackChunkName: "ProjectsPage" */ "./pages/projects/TheProjects.vue"
  );
const TheCompanies = () =>
  import(
    /* webpackChunkName: "CompaniesPage" */ "./pages/companies/TheCompanies.vue"
  );
const TheDocuments = () =>
  import(
    /* webpackChunkName: "DocumentsPage" */ "./pages/documents/TheDocuments.vue"
  );
const TheSales = () =>
  import(
    /* webpackChunkName: "SalesPage" */ "./pages/salesopportunities/TheSalesOpportunities.vue"
  );
const TheDocumentNumbers = () =>
  import(
    /* webpackChunkName: "DocumentNumbersPage" */ "./pages/documents/TheDocumentNumbers.vue"
  );
const TheDisciplines = () =>
  import(
    /* webpackChunkName: "DisciplinesPage" */ "./pages/documents/TheDisciplines.vue"
  );
const TheDocumentTypes = () =>
  import(
    /* webpackChunkName: "DocumentTypesPage" */ "./pages/documents/TheDocumentTypes.vue"
  );
const TheLicenseList = () =>
  import(
    /* webpackChunkName: "LicensesPage" */ "./pages/licenses/TheLicenseList.vue"
  );
const TheLicenseForm = () =>
  import(
    /* webpackChunkName: "LicensesPage" */ "./pages/licenses/TheLicenseForm.vue"
  );
const Subscription = () =>
  import(
    /* webpackChunkName: "SubscriptionsPage" */ "./pages/subscription/TheSubscriptions.vue"
  );

const SubscriptionType = () =>
  import(
    /* webpackChunkName: "SubscriptionsPage" */ "./pages/subscription/steps/SubscriptionType.vue"
  );
const SubscriptionDetails = () =>
  import(
    /* webpackChunkName: "SubscriptionsPage" */ "./pages/subscription/steps/SubscriptionDetails.vue"
  );
const LicenseSettingsProduct = () =>
  import(
    /* webpackChunkName: "SubscriptionsPage" */ "./pages/subscription/steps/LicenseSettingsProduct.vue"
  );
const LicenseSettingsLimits = () =>
  import(
    /* webpackChunkName: "SubscriptionsPage" */ "./pages/subscription/steps/LicenseSettingsLimits.vue"
  );
const LicenseSettingsBehavior = () =>
  import(
    /* webpackChunkName: "SubscriptionsPage" */ "./pages/subscription/steps/LicenseSettingsBehavior.vue"
  );
const SubConfirmation = () =>
  import(
    /* webpackChunkName: "SubConfirmation" */ "./pages/subscription/steps/ConfirmationNewSubscription.vue"
  );

const TheSubscription = () =>
  import(
    /* webpackChunkName: "SubscriptionsPage" */ "./pages/subscription/TheSubscriptionForm.vue"
  );

const SubscriptionsStepsForm = () =>
  import(
    /* webpackChunkName: "SubscriptionsStepsForm" */ "./pages/subscription/TheSubscriptionStepForm.vue"
  );

const TheProductVersions = () =>
  import(
    /* webpackChunkName: "ProductVersion" */ "./pages/productversions/TheProductVersions.vue"
  );

const AuthUser = () =>
  import(/* webpackChunkName: "UserAuth" */ "./pages/auth/UserAuth.vue");

const TheCompanyApiKey = () =>
  import("./pages/companyapikey/TheCompanyApiKey.vue");

const TheUsers = () => import("./pages/users/TheUsers.vue");

const NoPermission = ()  => import("./pages/NoPermissions.vue");

const SignatureGenerator = () => import("./pages/home/TheSignatureGenerator.vue")

const TheNewsList = () => import("./pages/news/TheNewsList.vue");

const TheNews = () => import("./pages/news/TheNews.vue");

const Test = ()  => import("./pages/news/TheNews.vue");

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: "/", redirect: "/home", meta: { notAuthRequired: true } },
    { path: "/test", component: Test },
    { path: "/home", component: TheHome },
    { path: "/signatureGenerator", component: SignatureGenerator},
    { path: "/projects", component: TheProjects },
    { path: "/companies", component: TheCompanies },
    {
      path: "/documents",
      component: TheDocuments,
      redirect: "/documents/documentnumbers",
      children: [
        { path: "documentnumbers", component: TheDocumentNumbers },
        { path: "disciplines", component: TheDisciplines },
        { path: "documenttypes", component: TheDocumentTypes },
      ],
    },
    { path: "/sales", component: TheSales },
    { path: "/news", component: TheNewsList },
    {
      path: "/news/new",
      component: TheNews,
      meta: { requiresPolicy: "isNewsManager" }
    },
    { path: "/news/:id", component: TheNews, props: true },
    { path: "/subscriptions", component: Subscription },
    {
      path: "/subscriptions/steps",
      component: SubscriptionsStepsForm,
      meta: { requiresPolicy: "isLicenseSubscriptionManager" },
      redirect: "/subscriptions/steps/subscriptionType",
      children: [
        {
          path: "subscriptionType",
          component: SubscriptionType,
          meta: { requiresPolicy: "isLicenseSubscriptionManager" },
        },
        {
          path: "subscriptionDetails",
          component: SubscriptionDetails,
          meta: { requiresPolicy: "isLicenseSubscriptionManager" },
        },
        {
          path: "licenseSettingsProduct",
          component: LicenseSettingsProduct,
          meta: { requiresPolicy: "isLicenseSubscriptionManager" },
        },
        {
          path: "licenseSettingsLimits",
          component: LicenseSettingsLimits,
          meta: { requiresPolicy: "isLicenseSubscriptionManager" },
        },
        {
          path: "licenseSettingsBehavior",
          component: LicenseSettingsBehavior,
          meta: { requiresPolicy: "isLicenseSubscriptionManager" },
        },
        {
          path: "confirmation",
          component: SubConfirmation,
          meta: { requiresPolicy: "isLicenseSubscriptionManager" },
        },
      ],
    },
    { path: "/subscriptions/:id", component: TheSubscription, props: true },
    { path: "/licenses", component: TheLicenseList },
    {
      path: "/licenses/new",
      component: TheLicenseForm,
      meta: { requiresPolicy: "isLicenseSubscriptionManager" },
    },
    { path: "/licenses/decrypted", component: TheLicenseForm },
    { path: "/licenses/:id", component: TheLicenseForm, props: true },
    { path: "/productversions", component: TheProductVersions },
    { path: "/machines", component: TheSales },
    {
      path: "/companiesapikey",
      component: TheCompanyApiKey,
      meta: { requiresPolicy: "isLicenseSubscriptionManager" },
    },
    {
      path: "/users",
      component: TheUsers,
      meta: { requiresPolicy: "isUsersManager" },
    },
    { path: "/auth", component: AuthUser, meta: { notAuthRequired: true } },
    { path: "/nopermission", component: NoPermission}
  ],
  linkActiveClass: "active",
});



/**
 * Defines a route guard
 */
router.beforeEach(async function(to, _, next) {
  const authStore = useAuthStore();
  if (to.meta.notAuthRequired) {
    // if route does not required that user is authenticated
    next();
  } else if (authStore.isLoggedIn) {
    // if user is authenticated
    // now check if the token is still valid
    authStore.checkAndRefreshingToken();

    // now check if for a specific route a policy limitation is configured
    if(to.meta.requiresPolicy) {
      const result = await authStore.checkPolicies(to.meta.requiresPolicy);
      if(result) {
        next();
      } else {
        next({path: "/NoPermission"});
      }
    } else {
      next();
    }
  } else {
    // otherwise redirect to /auth
    next({ path: "/auth", query: { redirectFrom: to.fullPath } });
  }
});

export default router;
