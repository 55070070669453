<template>
  <div class="card">
    <div v-if="!toggleable || (toggleable && !collapsed)">
      <slot></slot>
    </div>
  </div>
</template>

<style scoped>
.card {
  margin: 2rem auto;
  max-width: 98%;
  padding: 1rem;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
}
</style>

<script>
import { ref } from "vue";

export default {
  props: {
    toggleable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    const collapsed = ref(false);

    return {
      collapsed,
    };
  },
};
</script>
